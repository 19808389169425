<template>
  <div>
    <a-modal
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner">

      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        ></StreamBarcodeReader>
      </div>
    </a-modal>
    <a-modal v-model:visible="printingModal" :footer="null" wrapClassName="printingModal">
      <a :href="printingUrl" target="_blank">
        <button>
          {{ t('listRMA.printPackageListNow') }}
        </button>
      </a>
    </a-modal>
    <!-- :confirm-loading="confirmLoading" -->
    <a-modal
      :title="ModelTitle"
      v-model:visible="sendPackageModel"
      @ok="onSendPackageNew('CN')"
      okText="SendPackage"
      :okButtonProps="{ disabled: confirmDisabled, loading: confirmLoading }"
      :afterClose="onCloseSendPackage"
      :footer="ModelFooter"
    >
      <span style="margin-top: 10px"><b>{{ $t("route.TrackingNumber") }}</b></span>
      <a-input-search :placeholder="$t('route.pleaseCheckTrackNumber')" v-model:value="trackingNumber" style="padding-left:10px;" 
        @search="showDomesticCurrencyValue" @change="DomesticCurrencyValueChange" :disabled="!confirmDisabled">
        <template #enterButton>
          <a-button>Check</a-button>
        </template>
      </a-input-search>
      <div v-if="DomesticCurrencyValueModel">
        <div style="display:flex; justify-content: space-around; padding: 10px 0;">
          <span>{{ $t('sendPackageToNAV.TotalQuantity') }}: {{ DomesticCurrencyValueData.length }}</span>
          <span>{{ $t('sendPackageToNAV.TotalValue') }}: {{ totalVlaue }}</span>
        </div>
        <a-table
          :columns="DomesticCurrencyValueColumns"
          :data-source="DomesticCurrencyValueData"
        ></a-table>
      </div>
    </a-modal>
    <a-modal :title="$t('sendPackageToNAV.sendPlaceSelect')" v-model:visible="sandPlaceDialog" :footer="null" wrapClassName="printingModal">
      <div class="send-btn">
        <a-button type="primary" @click="onSendPackageNew('CN')">{{ $t('sendPackageToNAV.sendToCN') }}</a-button>
        <a-button type="primary" @click="onSendPackageNew('MC')">{{ $t('sendPackageToNAV.sendToMC') }}</a-button>
      </div>
    </a-modal>
    <div class="home">
      <a-row style="margin-left: 5px">
        <a-col :sm="24" :md="24" :xl="24">
          <a-row>
            <a-col :sm="22" :md="11" :xl="8" style="margin-right: 20px;">
              <span style="margin-top: 10px"><b>{{ $t("route.ScanYourRMAProduct") }}</b></span>
              <a-input-search placeholder="" v-model:value="productScan" @pressEnter="onHandleProductScan" enter-button @search="onHandleProductScan">
                <template #prefix>
                  <a-button type="primary" @click="toggleScanner">
                    <template #icon>
                      <ScanOutlined />
                    </template>
                  </a-button>
                </template>
              </a-input-search>
            </a-col>
            <a-col :sm="22" :md="11" :xl="8" style="margin-right: 20px;">
              <span style="margin-top: 10px"><b>{{ $t("route.TrackingNumber") }}</b></span>
              <a-input-search placeholder="" v-model:value="trackingNumber" :disabled="!hasScannedItem" style="padding-left:10px">
                <template #prefix>
                  <BarcodeOutlined/>
                </template>
              </a-input-search>
            </a-col>
            <a-col :sm="24" :md="24" :xl="6" style="text-align: 'center'; margin-top: 23px;">
              <a-button type="primary" @click="showSelectSendPlace" v-if="isShop && !isCHN" :disabled="!hasScannedItem || trackingNumber===''">
                {{ $t("route.PreparePackage") }}
              </a-button>
              <a-button type="primary" @click="onPreparePackage" v-else :disabled="!hasScannedItem || trackingNumber===''" style="margin-right: 20px;">
                <!-- <template #icon>
                  <PlusOutlined />
                </template> -->
                {{ $t("route.PreparePackage") }}
              </a-button>
              <a-button type="link" @click="onClickClearAll">
                {{ $t("route.ClearAll") }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <LastProduct :lastItem="lastItem" :unScannedItemCount="UnScannedItemCount" :scannedItemCount="ScannedItemCount"></LastProduct>
        <a-row class="declare_total" v-if="isShop && !isCHN">{{ $t('sendPackageToNAV.TotalValue') }}: {{ declareTotalValue }}</a-row>
        <a-row class="declare_total" v-if="isShop && !isCHN">{{ $t('sendPackageToNAV.TotalWeight') }}: {{ declareTotalWeight }}</a-row>
      </a-row>
    </div>

    <ProductList :waitingPackageData="waitingPackageData" :state="PreparePackageState"></ProductList>
    <ProductDetail :stateData="PreparePackageState"></ProductDetail>
  </div>
</template>
<script> 
  import { onMounted, createVNode, ref, computed, watch } from 'vue'
  import { Modal, message, notification } from 'ant-design-vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { UserOutlined, ScanOutlined, ExclamationCircleOutlined, UserSwitchOutlined, BarcodeOutlined, CalendarOutlined, FileSearchOutlined, BorderlessTableOutlined, PlusOutlined } from '@ant-design/icons-vue';
  import { formatDate } from '@/utils/utils'
  import { StreamBarcodeReader } from "vue-barcode-reader"
  import ProductList from '@/components/rma/ProductList'
  import LastProduct from '@/components/rma/LastProduct'
  import ProductDetail from '@/components/rma/ProductDetail'
  import PreparePackageState from './state/PreparePackage'
  import { useI18n } from 'vue-i18n';

  export default {
    components: {
      ProductDetail,
      UserOutlined,
      UserSwitchOutlined,
      BarcodeOutlined,
      CalendarOutlined,
      FileSearchOutlined,
      BorderlessTableOutlined,
      ScanOutlined,
      ProductList,
      LastProduct,
      PlusOutlined,
      StreamBarcodeReader
    },
    setup() {
      const { getRmaByStoreIDStatus, updateRmaStatus, printPackageList, getPackageStatus, postSendPackage, postSendPackageNav, getPackageprice } = useRmaRepositories()
      const { selectedItem } =  PreparePackageState()
      const waitingPackageData = ref([])
      const scannedItem = ref([])
      const productScan = ref("")
      const lastItem = ref({})
      const showScanner = ref(false)
      const printingUrl = ref("")
      const printingModal = ref(false)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const BarcodeReader = ref()
      // const canSendNav = ref(true)
      const isShop = localStorage.getItem("usertype") === "shop"
      const isCHN = localStorage.getItem("country_code") === "CHN"
      const sendPackageModel = ref(false)
      const confirmDisabled = ref(true)
      const confirmLoading = ref(false)
      const sandPlaceDialog = ref(false)
      const DomesticCurrencyValueModel = ref(false)
      const DomesticCurrencyValueData = ref([])
      const ModelTitle = ref('')
      const ModelFooter = ref(false)
      const declareTotalValue = ref(0)
      const declareTotalWeight = ref(0)
      const DomesticCurrencyValueColumns = [
        {
          title: 'Item',
          dataIndex: 'product_sku',
          key: 'product_sku',
          align: 'center'
        },
        {
          title: 'Size',
          dataIndex: 'product_size',
          key: 'product_size',
          align: 'center'
        },
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center'
        },
        {
          title: 'Value',
          dataIndex: 'declare_value',
          key: 'declare_value',
          align: 'center'
        },
        {
          title: 'Currency',
          dataIndex: 'declare_cur',
          key: 'declare_cur',
          align: 'center'
        },
      ];

      onMounted(() => {
        ModelTitle.value = t('sendPackageToNAV.DomesticCurrencyValue')
        init()
      })

      const toggleScanner= () => {
        showScanner.value = !showScanner.value
      }

      const onLoaded = () => {}

      const onCloseScanner = () => {}

      const onDecode = (result) => {
        productScan.value = result
        // console.log("decoded")
        onHandleProductScan()
      }

      watch(showScanner, (newValues, prevValues) => {
        if (!newValues) BarcodeReader.value.codeReader.stopStreams()
      })

      const ScannedItemCount = computed(() => {
        return scannedItem.value.length
      });

      const UnScannedItemCount = computed(() => {
        return  waitingPackageData.value.length -scannedItem.value.length
      });
      const hasScannedItem = computed(() => {
        return scannedItem.value.length > 0
      });
      const trackingNumber = ref("")
      
      const init = async () => {

        try {
          const WaitingPackage = await getRmaByStoreIDStatus('?status_code=1')
          const RejectedByStore = await getRmaByStoreIDStatus('?status_code=18')
          waitingPackageData.value = [...WaitingPackage.data, ...RejectedByStore.data]
        } catch (error) {
          console.log(error, "status_codeon Mounted")
        }

        // getRmaByStoreIDStatus('?status_code=1').then((res)=>{
        //   waitingPackageData.value = res.data
        // }).catch((err)=>{
        //   console.log(err, "status_codeon Mounted")
        // })

        // getRmaByStoreIDStatus('?status_code=18').then((res)=>{
        //   waitingPackageData.value = [...waitingPackageData.value, ...res.data ]
        // }).catch((err)=>{
        //   console.log(err, "status_codeon Mounted")
        // })

      }

      const reset = ()=> {
        scannedItem.value = []
        waitingPackageData.value = []
        trackingNumber.value = ""
        productScan.value = ""
        sendPackageModel.value = false
        sandPlaceDialog.value = false
        confirmDisabled.value = true
        confirmLoading.value = false
        declareTotalValue.value = 0
        declareTotalWeight.value = 0
        init()
      }

      // APMRMA-000179
      const onHandleProductScan = () => {
        // console.log("productScan", productScan.value)
        const foundItem = waitingPackageData.value.find((data) => {
          return data.RmaID === productScan.value
        })

        const duplicated = scannedItem.value.find((data) => {
          return data.RmaID === productScan.value
        })

        if (duplicated) {
          notification.error({
            message: 'RMA added',
            description: "RMA already added"
          })
          return
        }

        if (!foundItem) {
          return notification.error({
            message: 'RMA not found',
            description: "Could not find any data in your search"
          })
        } else {
          notification.success({
            message: 'RMA found',
            description: "RMA added for sending out"
          })
        }

        waitingPackageData.value = waitingPackageData.value.filter((data) => {
          return foundItem.id !== data.id
        })

        if (foundItem.declare_item) {
          declareTotalValue.value = declareTotalValue.value + Number(foundItem.declare_item.DeclareValue)
          declareTotalWeight.value = declareTotalWeight.value + Number(foundItem.declare_item.weight)
        }

        foundItem.is_scanned = true
        foundItem.rma_id = foundItem.RmaID
        foundItem.receive_item_date = formatDate(foundItem.receive_item_date)
        foundItem.product_image = "https://imgcdn.apm-monaco.cn/products-images/" + foundItem.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
        waitingPackageData.value.unshift(foundItem)

        scannedItem.value.push(foundItem)

        lastItem.value = foundItem
        lastItem.value.imgsrc = "https://imgcdn.apm-monaco.cn/products-images/"+lastItem.value.product_sku+".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
        // console.log(hasScannedItem, "foundItem")
        productScan.value = ""
      }

      const onClickClearAll = () => {
        Modal.confirm({
          title: 'Are you sure to cancel the tracking?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            // reset()
            for (var i=0; i < waitingPackageData.value.length; i++) {
              waitingPackageData.value[i].is_scanned = false
            }
            scannedItem.value = []
            trackingNumber.value = ""
            declareTotalValue.value = 0
            declareTotalWeight.value = 0
          },
          onCancel() {},
        });
      }

      const isTrackingNumberVaild = (trackingNo) => {
        const isnum = !/[^a-z0-9_.@()-]/i.test(trackingNo ? trackingNo : trackingNumber.value)
        if (!isnum) {
          return false
        }
        return true
      }

      const onPreparePackage = async () => {
        if (!isTrackingNumberVaild()) {
          notification.error({
            message: 'Please Enter Vaild Tracking Number',
            description: `RMA Update Error, Please tracking cannot contain < > : " / \\ | ? * `
          })
          return
        }

        // console.log('onPreparePackage', trackingNumber.value)
        const arrayOfID = []
        const updateAllRma = scannedItem.value.map((row) => {
          arrayOfID.push(row.id)
          const post_data = {
            id: row.id,
            rma_status_id: 2,
            retail_carrier_code: trackingNumber.value
          }
          return updateRmaStatus(post_data).then((data) => {
            return data
          });
          // return updateRmaStatus(row.id, 2, trackingNumber.value).then((data) => {
          //   return data
          // });
          // reset()
        });

        Promise.all(updateAllRma).then(async (data) => {
          // console.log(data, "update Rma response");
          notification.success({
            message: 'RMA Updated',
            description: "RMA Updated"
          })
          var result = await printPackageList(arrayOfID)
          // window.open(result.data.url, '_blank');
          printingUrl.value = result.data.url
          printingModal.value = true
          // if (isShop && isCHN) {
          //   printingModal.value = true
          // } else {
          //   // const can_send = await getPackageStatus(trackingNumber.value, 0)
          //   // if (can_send) canSendNav.value = false
          // }
          // reset()
          init()
        }).catch((err) => {
          notification.error({
            message: 'RMA Error',
            // description: err
          })
          console.log(err, "error Promise All")
          reset()
        }) 
      }

      const showSelectSendPlace = () => {
        sandPlaceDialog.value = true
      }

      const showSendPackage = () => {
        sandPlaceDialog.value = false
        sendPackageModel.value = true
      }

      const DomesticCurrencyValueChange = () => {
        ModelTitle.value = t('sendPackageToNAV.DomesticCurrencyValue')
        ModelFooter.value = false
      }

      const checkSendPackage = async () => {
        confirmDisabled.value = !(await getPackageStatus(trackingNumber.value, 0))
      }

      const showDomesticCurrencyValue = async () => {
        const result = await getPackageprice(trackingNumber.value, '0')
        if (result.success) {
          ModelTitle.value = t('sendPackageToNAV.DomesticCurrencyValue')
          ModelFooter.value = false
          if (result.data.length > 0) {
            DomesticCurrencyValueData.value = result.data
            DomesticCurrencyValueModel.value = true
          } else {
            DomesticCurrencyValueModel.value = false
            notification.error({
              message: t('sendPackageToNAV.dataNotFound')
            })
          }
        } else {
          DomesticCurrencyValueModel.value = false
          if (result.code == 'InvalidParameter.TrackingNoNotSent') {
            confirmDisabled.value = false
            ModelTitle.value = 'Would you like to send the Package?'
            ModelFooter.value = undefined
            // notification.error({
            //   message: "RMA Error",
            //   description: t('InvalidParameter.TrackingNoNotSent')
            // })
          } else {
            notification.error({
              message: "RMA Error"
            })
          }
        }
      }

      const totalVlaue = computed(() => {
        let totalVlaue = 0
        DomesticCurrencyValueData.value.forEach(v => {
          totalVlaue += v.declare_value
        })
        return totalVlaue
      });

      const onSendPackage = async () => {
        // if (!isTrackingNumberVaild()){
        //   notification.error({
        //     message: 'Please Enter Vaild Tracking Number',
        //     description: `RMA Update Error, Please tracking cannot contain < > : " / \\ | ? * `
        //   })
        //   return
        // }

        // Modal.confirm({
        //   title: 'Would you like to send the Package?',
        //   icon: createVNode(ExclamationCircleOutlined),
        //   async onOk() {
        //     const result = await postSendPackage(trackingNumber.value, 0)
        //     if (result.success) {
        //       notification.success({
        //         message: 'RMA Updated',
        //         description: "RMA Order was sent to NAV"
        //       })
        //       // canSendNav.value = true
        //       printingModal.value = true
        //       reset()
        //     } else {
        //       notification.error({
        //         message: 'RMA Error',
        //         description: result.message
        //       })
        //     }
        //   },
        //   onCancel() {},
        // });
        confirmLoading.value = true
        const result = await postSendPackage(trackingNumber.value, '0')
        if (result.success) {
          notification.success({
            message: 'RMA Updated',
            description: t('InvalidParameter.commonSuccess')
          })
          reset()
        } else {
          confirmLoading.value = false
          if (result.code != 'InvalidParameter.TrackingNo' && result.code != 'InvalidParameter.Locked' && result.code != 'InvalidParameter.Country') {
            return notification.error({
              message: 'RMA Error',
              description: t('InvalidParameter.commonError')
            })
          }
          notification.error({
            message: 'RMA Error',
            description: t(`${result.code}`)
          })
        }
      }

      const onSendPackageNew = async (factory) => {
        if (!isTrackingNumberVaild(trackingNumber.value)){
          notification.error({
            message: 'Please Enter Vaild Tracking Number',
            description: `RMA Update Error, Please tracking cannot contain < > : " / \\ | ? * `
          })
          return
        }

        const arrayOfID = []
        scannedItem.value.forEach((row) => {
          arrayOfID.push(row.id)  
        });

        confirmLoading.value = true
        const result = await postSendPackageNav(trackingNumber.value, factory, arrayOfID)
        console.log(result)
        if (result.success) {
          notification.success({
            message: 'RMA Updated',
            description: t('InvalidParameter.commonSuccess')
          })
          reset()
          const result = await printPackageList(arrayOfID)
          printingUrl.value = result.data.url
          printingModal.value = true
        } else {
          confirmLoading.value = false
          notification.error({
            message: 'RMA Error',
            description: result.message
          })
        }
      }

      const onCloseSendPackage = () => {
        trackingNumber.value = ''
        confirmDisabled.value = true
        confirmLoading.value = false
        DomesticCurrencyValueModel.value = false
        DomesticCurrencyValueData.value = []
        ModelTitle.value = t('sendPackageToNAV.DomesticCurrencyValue')
        ModelFooter.value = ''
        declareTotalValue.value = 0
        declareTotalWeight.value = 0
      }

      /*
        if (localStorage.getItem('workerId')) {
        isWorkerLogged.value = true
        }
      */
      return {
        waitingPackageData,
        BarcodeOutlined,
        PlusOutlined,
        productScan,
        onHandleProductScan,
        hasScannedItem,
        trackingNumber,
        onPreparePackage,
        onSendPackage,
        onSendPackageNew,
        ScannedItemCount,
        UnScannedItemCount,
        lastItem,
        onClickClearAll,
        onDecode,
        onLoaded,
        StreamBarcodeReader,
        showScanner,
        toggleScanner,
        printingUrl,
        printingModal,
        PreparePackageState,
        t,
        locale,
        onCloseScanner,
        BarcodeReader,
        isShop,
        isCHN,
        // canSendNav,
        showSendPackage,
        checkSendPackage,
        sendPackageModel,
        confirmDisabled,
        confirmLoading,
        onCloseSendPackage,
        DomesticCurrencyValueModel,
        showDomesticCurrencyValue,
        DomesticCurrencyValueData,
        DomesticCurrencyValueColumns,
        totalVlaue,
        ModelTitle,
        ModelFooter,
        DomesticCurrencyValueChange,
        declareTotalValue,
        declareTotalWeight,
        showSelectSendPlace,
        sandPlaceDialog
        // isWorkerLogged
      }
    },
  }
</script>
<style lang="scss" scoped>
.waiting_package_data_row {
  background:#FF4D4D;
}

.waiting_package_data_row.is_scanned {
  background:#86D067;
}
.home::v-deep {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-card .ant-card-body::v-deep {
  padding: 10px!important;
}

.ant-image::v-deep {
  float: right!important;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge2 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #86D067;
  color: #fefefe;
}

.badge3 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #FF4D4E;
  color: #fefefe;
}

button:disabled::v-deep,
button[disabled]::v-deep {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.loading {
  left:46%;
  top:50%;
  position: absolute;
}
</style>
<style lang="scss">
.scanner-container {
  .laser {
    top: 50%;
  }
}
.modal-with-height {
  .ant-modal-body {
    min-height:300px;
  }
}
.ant-input-search-enter-button {
  padding-top: 0px!important;
  padding-left: 0px!important;
  padding-bottom: 0px!important;
}
.declare_total {
  width: 100%;
  font-size: 16px;
  color: #f00;
}

.send-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>